import React, { useState, useContext } from "react";

import { DataContext } from "../../../context/dataContext/dataContext";

// MARKUP
const GarageSelection = () => {
  // CONTEXT
  const [DataValue, setDataValue] = useContext(DataContext);

  const [selectedValue, setselectedValue] = useState(
    DataValue.userData.msrp ? "MSRP" : "DEALER COST"
  );

  function changeValue(e) {
    setselectedValue(e);
    if (e === "MSRP") {
      setDataValue({
        ...DataValue,
        userData: { ...DataValue.userData, msrp: true },
      });
    } else {
      setDataValue({
        ...DataValue,
        userData: { ...DataValue.userData, msrp: false },
      });
    }
  }

  return (
    <select value={selectedValue} onChange={(e) => changeValue(e.target.value)}>
      <option value="MSRP">MSRP</option>
      <option value="DEALER COST">DEALER COST</option>
    </select>
  );
};

export default GarageSelection;
