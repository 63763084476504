//GET AND UPDATE VERSION
export function updateVersion(givenVersion) {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    //IF VERSION IS NULL
    //SET THE VERSION OF THE WEBSITE
    //DELETE ALL PREVIUS LOCAL STORAGE
    if (localStorage.getItem("version") === null) {
      localStorage.clear();
      localStorage.setItem("version", givenVersion);
    }

    //IF LOCAL STORAGE VERSION IS NOT UP TO DATE
    //CLEAR THE LOCAL STORAGE
    //SET THE VERSION OF THE WEBSITE
    //RELOAD PAGE
    if (localStorage.getItem("version") !== givenVersion) {
      localStorage.clear();
      localStorage.setItem("version", givenVersion);
      window.location.reload(false);
    }
  }
}
