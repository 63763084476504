import React, { useState } from "react";
import { Link } from "gatsby";

//CSS
import * as CSS from "./selection.module.scss";

//MUI
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// COMPONENTS
import { BlackButtonArrow } from "../../../buttons/buttons";
import { SearchSku } from "../../searchSku/searchSku";

export const MenuSelection = ({ lang, closefunction }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={CSS.selectors}>
      {/* MAGS */}
      <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p>{lang ? "Mags" : "Wheels"}</p>
        </AccordionSummary>
        <AccordionDetails>
          <Link to="/wheels-vehicle">
            <BlackButtonArrow givenFunction={closefunction}>
              {lang ? "Recherche par véhicule" : "Search by vehicle"}
            </BlackButtonArrow>
          </Link>
          <Link to="/wheels-size">
            <BlackButtonArrow givenFunction={closefunction}>
              {lang ? "Recherche par taille" : "Search by size"}
            </BlackButtonArrow>
          </Link>
        </AccordionDetails>
      </Accordion>
      {/* TIRES */}
      <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <p>{lang ? "Pneus" : "Tires"}</p>
        </AccordionSummary>
        <AccordionDetails>
          <Link to="/tires-size">
            <BlackButtonArrow givenFunction={closefunction}>
              {lang ? "Recherche par taille" : "Search by size"}
            </BlackButtonArrow>
          </Link>
        </AccordionDetails>
      </Accordion>
      {/* COMBOS */}
      <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panelC"}
        onChange={handleChange("panelC")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelC-content"
          id="panelC-header"
        >
          <p>{lang ? "Ensembles" : "Sets"}</p>
        </AccordionSummary>
        <AccordionDetails>
          <Link to="/sets">
            <BlackButtonArrow givenFunction={closefunction}>
              {lang ? "Recherche par véhicule" : "Search by vehicle"}
            </BlackButtonArrow>
          </Link>
        </AccordionDetails>
      </Accordion>
      {/* MARQUES */}
      {/* <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <p>Marques</p>
        </AccordionSummary>
        <AccordionDetails>
          <BlackButtonArrow>Fuel Offroad</BlackButtonArrow>
          <BlackButtonArrow>Black Rhino</BlackButtonArrow>
          <BlackButtonArrow>XD</BlackButtonArrow>
          <BlackButtonArrow>Mayhem</BlackButtonArrow>
          <BlackButtonArrow>Voir toutes les marques</BlackButtonArrow>
        </AccordionDetails>
      </Accordion> */}
      {/* PROMOTIONS */}
      {/* <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <p>Promotions</p>
        </AccordionSummary>
        <AccordionDetails>
          <BlackButtonArrow>Promo</BlackButtonArrow>
        </AccordionDetails>
      </Accordion> */}
      {/* SKU */}
      <Accordion
        className={CSS.accSummary}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <p>{lang ? "Recherche par SKU" : "Search by SKU"}</p>
        </AccordionSummary>
        <AccordionDetails>
          <SearchSku />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
