import React, { useState } from "react";
import { DataContext } from "./dataContext";

// FUNCTIONS
import { getLanguage } from "../../functions/getLanguage";
import { getCart } from "../../functions/getCart";
import { getVehicle } from "../../functions/getVehicle";
import { getAccount } from "../../functions/account";

// markup
export const DataGlobalContext = ({ children }) => {
  // Get lang
  let lang = getLanguage();
  let isFrench;
  if (lang === "fr") {
    isFrench = true;
  } else {
    isFrench = false;
  }

  // GET ACCOUNT
  let acc = getAccount();

  // GET CART
  let c = getCart();
  let ct = c.totaltires + c.totalwheels;

  // GET VEHICLE
  let v = getVehicle();
  if (v === null) {
    isFrench ? (v = "non sélectionné") : (v = "unselected");
  }

  // DATE
  var now = new Date();
  // HOUR
  let hour = now.getHours();
  // let hour = 20;
  // DAY
  let day = now.getDay();

  // DATE
  let date = JSON.stringify(now.getDate() + now.getMonth() + now.getFullYear());

  // FOR GENERAL SITE SET UP DISPLAYS
  let xshowSkewedPrices = false;
  let xshowDiscountBand = true;
  let xshowSkewedOldPrices = false;
  if (
    hour > 17 ||
    hour < 5 ||
    (day === 6 && hour > 12) ||
    (day === 6 && hour < 5) ||
    (day === 0 && hour > 12) ||
    (day === 0 && hour < 5)
  ) {
    xshowSkewedPrices = true;
    xshowDiscountBand = false;
    xshowSkewedOldPrices = false;
  }
  if (acc.logged === true) {
    xshowSkewedPrices = false;
    xshowDiscountBand = false;
    if (acc.garage === true) {
      xshowSkewedOldPrices = false;
    }
    if (acc.garage === false) {
      xshowSkewedOldPrices = true;
    }
  }

  // Set initial value
  const [DataValue, setDataValue] = useState({
    //
    lang: isFrench,
    cartTotal: ct,
    //
    QVopen: false,
    QVSKU: "",
    lifted: false,
    page: "index",
    updateCart: false,
    totalInCart: c.totalItems,
    changeVehicleDrawer: false,
    vehicleName: v,
    hour: hour,
    day: day,
    userData: acc,
    showSkewedPrices: xshowSkewedPrices,
    showDiscountBand: xshowDiscountBand,
    showSkewedOldPrices: xshowSkewedOldPrices,
    percentageOff: 1,
    percentageOffText: "0",
    date: date,
  });

  return (
    <>
      <DataContext.Provider value={[DataValue, setDataValue]}>
        {children}
      </DataContext.Provider>
    </>
  );
};
