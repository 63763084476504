import * as React from "react";

// MUI
import Button from "@mui/material/Button";

// CSS
import * as CSS from "./buttons.module.scss";

// ICONS
import filterIcon from "../../icons/filterb.svg";

// MARKUP
export const BlackButton = ({ children }) => {
  return (
    <Button variant="contained" fullWidth={true} className={CSS.blackButton}>
      {children}
    </Button>
  );
};

export const BlackButtonSubmit = ({ children }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      fullWidth={true}
      className={CSS.blackButtonArrow}
    >
      {children}
    </Button>
  );
};

export const BlackButtonArrow = ({ children, givenFunction }) => {
  return (
    <Button
      variant="contained"
      fullWidth={true}
      className={CSS.blackButtonArrow}
      onClick={givenFunction}
    >
      {children}
    </Button>
  );
};

export const WhiteButtonArrow = ({ children, givenFunction }) => {
  return (
    <Button
      variant="contained"
      fullWidth={true}
      className={CSS.whiteButtonArrow}
      onClick={givenFunction}
    >
      {children}
    </Button>
  );
};

export const BlackButtonArrowDisabled = ({ children }) => {
  return (
    <Button
      variant="contained"
      fullWidth={true}
      className={CSS.blackButtonArrow}
      disabled
    >
      {children}
    </Button>
  );
};

export const FilterButton = ({ total, givenFunction }) => {
  return (
    <Button
      variant="contained"
      fullWidth={true}
      className={CSS.filterButton}
      onClick={givenFunction}
    >
      <div>
        <img src={filterIcon} alt="filtre" />
        <p>FILTRES</p>
      </div>
      <p>{total} RÉSULTATS</p>
    </Button>
  );
};
