//GET LANGUAGE
export function getLanguage() {
  const isBrowser = typeof window !== "undefined";
  // IF IS BROWSER
  if (isBrowser) {
    // IF LANGUIAGE IS SET
    if (localStorage.getItem("language") !== null) {
      let x = localStorage.getItem("language");
      return x;
    } else {
      // IF LANGUAGE IS NOT SET
      let givenLanguage = navigator.language;
      let languageCode = givenLanguage.substring(0, 2);
      if (languageCode === "fr" || languageCode === "en") {
        // IF LANGUAGE IS DETECTED
        localStorage.setItem("language", languageCode);
        return languageCode;
      } else {
        // IF LANGUAGE NOT DETECTED
        localStorage.setItem("language", "en");
        return "en";
      }
    }
  } else {
    // IF IS NOT BROWSER
    return "en";
  }
}
