//get LocalStorage Cart
export function getVehicle() {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    if (localStorage.getItem("COvehicle") !== null) {
      let x = localStorage.getItem("COvehicle");
      return x;
    } else {
      return null;
    }
  }
}
