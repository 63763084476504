import React, { useContext } from "react";

// CONTEXT
import { DataContext } from "../../../context/dataContext/dataContext";

// MUI
import Badge from "@mui/material/Badge";

// ICONS
import cartIcon from "../../../icons/cartw.svg";

// CSS
import * as CSS from "./itemsInCart.module.scss";

// MARKUP
export const ItemsInCart = () => {
  // Cart in context
  const [DataValue] = useContext(DataContext);
  return (
    <Badge badgeContent={DataValue.cartTotal} color="error">
      <img src={cartIcon} className={CSS.cartIcon} alt="pannier" />
    </Badge>
  );
};
