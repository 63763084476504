import React, { useState, useContext } from "react";
import { Link } from "gatsby";

//CSS
import * as StyledComponents from "./navbar.module.scss";

//MUI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

//ICONS
import closeIcon from "../../../icons/close.svg";
import fire from "../../../images/fire.gif";

//COMPONENTS
import { ItemsInCart } from "../../cart/itemsInCart/itemsInCart";
import { MenuSelection } from "./selection/selection";
import { LangButton } from "../../language/language-btn";
import GarageSelection from "../../selections/garage_selection/garageSelection";

// CONTEXT
import { DataContext } from "../../../context/dataContext/dataContext";

export const NavBar = () => {
  //Data context
  const [DataValue] = useContext(DataContext);

  // OPEN OR CLOSE MENU
  const [Open, setOpen] = useState(false);
  const menu = (
    <Drawer
      className={StyledComponents.menu}
      anchor={"left"}
      open={Open}
      onClose={() => setOpen(false)}
    >
      <div className={StyledComponents.head}>
        {DataValue.userData.garage === false ? (
          <p>Canada Offroad</p>
        ) : (
          <p>DEALER LINE</p>
        )}
        <button onClick={() => setOpen(false)}>
          <img src={closeIcon} alt="fermer" />
        </button>
      </div>
      <MenuSelection
        lang={DataValue.lang}
        closefunction={() => setOpen(false)}
      />
      <button className={StyledComponents.nobtn} onClick={() => setOpen(false)}>
        <Link to="/returns">
          <p>
            {DataValue.lang ? "Retours et échanges" : "Returns and exchanges"}
          </p>
        </Link>
      </button>
      <button className={StyledComponents.nobtn} onClick={() => setOpen(false)}>
        <Link to="/best-price">
          <p>
            {DataValue.lang
              ? " Politique du meilleur prix"
              : "Best price policy"}
          </p>
        </Link>
      </button>
      <button className={StyledComponents.nobtn} onClick={() => setOpen(false)}>
        <Link to="/contact">
          <p>{DataValue.lang ? "Contactez-nous" : "Contact us"}</p>
        </Link>
      </button>
      <button className={StyledComponents.nobtn} onClick={() => setOpen(false)}>
        <Link to="/accounts">
          <p>{DataValue.lang ? "Mon compte" : "My account"}</p>
        </Link>
      </button>
      <LangButton />
    </Drawer>
  );

  function discountBand() {
    if (DataValue.percentageOff !== 1) {
      return (
        <div className={StyledComponents.promo}>
          <div className={StyledComponents.content}>
            {DataValue.lang ? "CODE: OFFROAD" : "CODE: OFFROAD"}
            {DataValue.percentageOffText}
          </div>

          <div className={StyledComponents.content}>
            <img src={fire} alt="" />
            {DataValue.percentageOffText}
            {DataValue.lang
              ? "% DE RABAIS SUR TOUS LES MAGS"
              : "% OFF ON ALL WHEELS"}
            <img src={fire} alt="" />
          </div>
          <div className={StyledComponents.content}>
            {DataValue.lang
              ? "APPLICABLE À LA CAISSE"
              : "APPLICABLE AT CHECKOUT"}
          </div>
        </div>
      );
    } else {
      return <div className={StyledComponents.blankPromo}> </div>;
    }
  }

  return (
    <>
      <AppBar className={StyledComponents.navbar}>
        <Toolbar className={StyledComponents.toolbar}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          {DataValue.userData.garage === false ? (
            <Link to="/">
              <p className={StyledComponents.title}>CANADA OFFROAD</p>
            </Link>
          ) : (
            <>
              <Link to="/accounts">
                <p className={StyledComponents.title}>DEALER LINE</p>
              </Link>
              <GarageSelection />
            </>
          )}

          <div>
            {/* <LangButton /> */}
            <Link to="/cart">
              <IconButton size="large" aria-label="Pannier" color="inherit">
                <ItemsInCart />
              </IconButton>
            </Link>
          </div>
        </Toolbar>
        {menu}
      </AppBar>
      {discountBand()}
    </>
  );
};
