import React, { useState, useEffect } from "react";

// FUNCTIONS
import { getLanguage } from "../../functions/getLanguage";

// CSS
import * as CSS from "./language-btn.module.scss";

//MARKUP
export const LangButton = () => {
  // GET LANGUAGE
  const [IsFrench, setIsFrench] = useState(true);
  useEffect(() => {
    let lang = getLanguage();
    if (lang === "fr") {
      setIsFrench(true);
    } else {
      setIsFrench(false);
    }
  }, []);

  function changeLanguage() {
    if (IsFrench) {
      localStorage.setItem("language", "en");
      window.location.reload(false);
    } else {
      localStorage.setItem("language", "fr");
      window.location.reload(false);
    }
  }
  return (
    <button className={CSS.button} onClick={() => changeLanguage()}>
      {IsFrench ? "English" : "Français"}
    </button>
  );
};
