// Get LocalStorage Account
export function getAccount() {
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    return;
  }
  if (localStorage.getItem("COaccount") !== null) {
    let x = localStorage.getItem("COaccount");
    return JSON.parse(x);
  } else {
    return {
      email: "error",
      logged: false,
      garage: false,
      msrp: true,
      net30: false,
      name: "",
    };
  }
}

export function loginAccount(data) {
  data = JSON.stringify(data);
  //Set Local Storage account
  localStorage.setItem("COaccount", data);
}

export function logoutAccount() {
  //Remove Local Storage account
  localStorage.removeItem("COaccount");
}
