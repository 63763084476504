import { updateLocalStorageCart } from "./updateCart";

//get LocalStorage Cart
export function getCart() {
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    let newCart = {
      wheels: [],
      tires: [],
      tpms: [],
      nuts: [],
      hr: [],
      totaltires: 0,
      totalwheels: 0,
      totaltpms: 0,
      totalnuts: 0,
      totalhr: 0,
      totalItems: 0,
    };
    return newCart;
  } else {
    if (localStorage.getItem("COcart") !== null) {
      let x = localStorage.getItem("COcart");
      return JSON.parse(x);
    } else {
      let newCart = {
        wheels: [],
        tires: [],
        tpms: [],
        nuts: [],
        hr: [],
        totaltires: 0,
        totalwheels: 0,
        totaltpms: 0,
        totalnuts: 0,
        totalhr: 0,
        totalItems: 0,
      };

      updateLocalStorageCart(newCart);

      return newCart;
    }
  }
}
