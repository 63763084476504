import * as React from "react";

// CSS
import "./src/globalStyles/global.css";

// Wraps every page in the Layout to avoid dismount
import { PageLayout } from "./src/components/layout/layout";

// gatsby-browser.js
export const onClientEntry = () => {
  if (typeof window.fbq === "undefined") {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "5597526313596082");
    fbq("track", "PageView");
  }
};

export const wrapPageElement = ({ element, props }) => {
  return <PageLayout {...props}>{element}</PageLayout>;
};
